import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { gql } from "graphql-tag";
import { apolloClient } from "../../apollo-client";
import {
  resetBooking,
  setBookingId,
  setCabinReservationId,
  setConfirmedBookingId,
  setConfirmedCabinReservationId,
  getBookingConfirmationAction,
  resetActiveBasketAndBooking,
  getBookingConfirmationFailAction,
  getBookingConfirmationSuccessAction,
  removeUnconfirmedBookingFailed,
  removeUnconfirmedBookingSuccess,
  setBookingSummary,
  setSelectedCabinReservationId,
  removeUnconfirmedBooking as removeUnconfirmedSlice,
  resetBookingSummary,
  getConfirmedBookingSummaryFailAction,
  getConfirmedBookingSummarySuccessAction,
  resetConfrimedBookingSummary,
  setConfirmedBookingSummary,
  setConfirmedSelectedCabinReservationId,
  getAvailableLocations,
  resetBookingSearch,
  setSingleSelectedLocationName,
  getConfirmedBookingSummaryAction,
  getConfirmedBookingCabinEssentialsAction,
  getExtras,
  getUpcomingBookings,
  getUpcomingBookingsFail,
  getUpcomingBookingsSuccess,
  removeSticky,
  confirmActiveBookingStepAction,
  setBookingStepsToUncompleted,
  resetReservation,
  setCabinId,
  setCabinTypeName,
  setIsCabinDda,
  setNumberOfBedrooms,
  setPetFriendly,
  setPrice,
  setPriceCheck,
  setPriceExpiry,
  getConfirmedBookingInfoAction,
  getConfirmedBookingInfoFailAction,
  getConfirmedBookingInfoSuccessAction,
  createCabinSearch,
  addExtraSuccess,
  addFeaturedExtraSuccess,
  removeExtraSuccess,
  addSpecificCabinSuccess,
  swapSpecificCabinSuccess,
  removeSpecificCabinSuccess,
  setBookingBedroom,
  initializeAddCabin,
  getPaymentOptionsAction,
  ResetActiveBasketAndBooking,
} from "../slices";
import {
  loginSelector,
  siteSettingsSelector,
  extraFilterSelector,
  bookingPersistedStateSelector,
  bookingSearchSelector,
  bookingSummarySelector,
  confirmedBookingSummarySelector,
  useBookingQueryStringSelector,
  locationsSelector,
  searchLocationsSelector,
  activeExperimentsStateSelector,
} from "../selectors";
import { getBookingStepByTag, getExtrasStepByTag, Nullable } from "../../utils";
import moment from "moment";
import {
  BookingConfirmation_S as BookingConfirmation,
  BookingConfirmationResponseUnion,
  BookingRemovalResponseUnion,
  BookingSummary_S as BookingSummary,
  ConfirmedBookingInfoResponseUnion,
  MutationRemoveUnconfirmedBookingArgs,
  QueryBookingConfirmationArgs,
  QueryConfirmedBookingInfoArgs,
  QueryUpcomingBookingsArgs,
  UpcomingBooking,
} from "@generated/types";
import { BookingSummaryCabin } from "../../interfaces/bookingSummary";
import { StepperTag } from "../../interfaces/booking";
import {
  loadGraphQLQuery,
  loadGraphQLMutation,
  loadCustomGraphQLQuery,
} from "src/utils";
import { LocationsDropdownItem } from "../../interfaces/bookingForm";
import { ExtrasStepperTag } from "../../interfaces/extras";

function* setSelectedCabinReservationIdSaga(
  action: ReturnType<typeof getBookingConfirmationSuccessAction>,
) {
  const { cabinReservations } = action.payload;
  const selectedCabinReservationId = (cabinReservations || [])[0]
    ?.cabinReservationId; // TODO: rework this to function for multiple cabins
  const bookingId = (cabinReservations || [])[0]?.bookingId; // TODO: rework this to function for multiple cabins
  if (selectedCabinReservationId) {
    yield put(setSelectedCabinReservationId(selectedCabinReservationId));
    yield put(
      setConfirmedSelectedCabinReservationId(selectedCabinReservationId),
    );
    yield put(setCabinReservationId(selectedCabinReservationId));
    yield put(setBookingId(bookingId));
  }
}

function* setConfirmedCabinReservationIdSaga(
  action: ReturnType<typeof getConfirmedBookingSummarySuccessAction>,
) {
  const { confirmedCabinReservationId } = yield select(
    bookingPersistedStateSelector,
  );
  const { cabinReservations } = action.payload.bookingConfirmation;
  const cabinReservationMatch = cabinReservations?.find(
    (cabin) => cabin?.cabinReservationId === confirmedCabinReservationId,
  );
  const hasSelectedCabinReservation =
    !!confirmedCabinReservationId && !!cabinReservationMatch;
  const cabinReservationId = hasSelectedCabinReservation
    ? cabinReservationMatch?.cabinReservationId
    : (cabinReservations || [])[0]?.cabinReservationId;
  const bookingId = hasSelectedCabinReservation
    ? cabinReservationMatch?.bookingId
    : (cabinReservations || [])[0]?.bookingId;

  if (cabinReservationId) {
    yield put(setConfirmedBookingId(bookingId));
    yield put(setConfirmedCabinReservationId(cabinReservationId));
  }
}

function makeCabinProps(cabin: BookingSummary): BookingSummaryCabin {
  const reservationExtras = (cabin.reservationExtras || []).map((resExtra) => ({
    extraName: resExtra?.extraName || "",
    extraSelectedDate: null, // TODO: Rob is going to surface this at Extra level from BSL, not currently able to do so
    ampm: null, // TODO: Rob is going to surface this at Extra level from BSL, not currently able to do so
    extraId: resExtra?.extraId,
    containsConfirmedExtraOption:
      (
        resExtra?.reservationExtraOptions?.filter(
          (eo) => eo?.extraStatus === "CONFIRMED",
        ) || []
      ).length > 0,
    extraOptions: resExtra?.reservationExtraOptions?.map(
      (opt) =>
        ({
          id: opt?.extraOptionId || "",
          extraOptionName: opt?.extraOptionName || "",
          extraOptionId: opt?.extraOptionId || "",
          quantity: opt?.quantity || 0,
          extraOptionStatus: opt?.extraStatus,
        }) as any,
    ) as any,
  }));

  return {
    cabinTypeName: cabin?.cabinTypeName || "",
    noOfBedrooms: cabin.noOfBedrooms || 0,
    petFriendly: !!cabin.petFriendly,
    disabledAccess: !!cabin.disabledAccess,
    numberPets: cabin.noOfPetsBooked || 0,
    reservationExtras,
  };
}

function formatDate(date: Date | string): string {
  return moment(date).format("dddd, MMMM Do YYYY");
}

function* setConfirmedBookingSummarySaga(
  action: ReturnType<typeof setConfirmedCabinReservationId>,
) {
  const selectedCabinReservationId = action.payload;
  const confirmedBooking: any = yield select(confirmedBookingSummarySelector);
  const { confirmation } = confirmedBooking;
  const { bookingReference, cabinReservations } =
    confirmation as BookingConfirmation;

  const bookingSummarySelectedCabin: Nullable<BookingSummary> =
    cabinReservations?.find(
      (cabin) => cabin?.cabinReservationId === selectedCabinReservationId,
    ) as Nullable<BookingSummary>;

  const bookingSummaryOtherCabins: BookingSummary[] = cabinReservations?.filter(
    (cabin) => cabin?.cabinReservationId !== selectedCabinReservationId,
  ) as BookingSummary[];

  if (
    selectedCabinReservationId &&
    bookingSummarySelectedCabin &&
    bookingReference
  ) {
    const selectedCabin = makeCabinProps(bookingSummarySelectedCabin);
    const otherCabins: BookingSummaryCabin[] = bookingSummaryOtherCabins.map(
      (cabin) => makeCabinProps(cabin),
    );

    const booking: any = {
      bookingReference,
      reservationEndDate: bookingSummarySelectedCabin?.reservationEndDate || "",
      reservationStartDate:
        bookingSummarySelectedCabin?.reservationStartDate || "",
      friendlyReservationStartDate: formatDate(
        bookingSummarySelectedCabin?.reservationStartDate || "",
      ),
      friendlyReservationEndDate: formatDate(
        bookingSummarySelectedCabin?.reservationEndDate || "",
      ),
      locationName: bookingSummarySelectedCabin?.locationName || "",
      selectedCabin: selectedCabin as BookingSummaryCabin,
      otherCabins,
    };

    yield put(setConfirmedBookingSummary(booking));
  }
}

function* bookingSearchUpdatedSaga() {
  const {
    dda,
    guests,
    selectedLocationIds: bookingLocationIds,
    promoCode,
    dateRange: bookingDateRange,
  } = yield select(bookingSearchSelector);

  const { sortMethod, activeFilters } = yield select(searchLocationsSelector);
  const queryData = yield select(useBookingQueryStringSelector);

  const { dateRange, selectedLocationIds } = queryData || {
    dateRange: bookingDateRange,
    selectedLocationIds: bookingLocationIds,
  };

  const startDate = moment(dateRange.startDateISO);
  const endDate = moment(dateRange.endDateISO);

  yield put(
    getAvailableLocations({
      locationsQuery: {
        request: {
          cabinTypeIds: [],
          bookingChannelId: process.env.NEXT_PUBLIC_BOOKING_CHANNEL_ID,
          disabledAccess: dda,
          endDate: endDate?.format("YYYY-MM-DD"),
          locationIds: selectedLocationIds,
          numberOfAdults: guests.adults,
          numberOfBedrooms: guests.bedrooms,
          numberOfChildren: guests.children,
          numberOfPets: guests.pets,
          numberOfInfants: guests.infants,
          startDate: startDate?.format("YYYY-MM-DD"),
          promoCode,
          sortMethod,
        },
        selectedTagIds:
          !!activeFilters && !!activeFilters?.length
            ? activeFilters
            : undefined,
      },
      filtered: !!activeFilters && !!activeFilters?.length ? true : false,
    }),
  );
}

function* setSelectedLocationName() {
  const { selectedLocationIds } = yield select(bookingSearchSelector);
  const { bookableLocations } = yield select(locationsSelector);
  if (selectedLocationIds?.length === 1) {
    const singleLocationDetails: LocationsDropdownItem =
      bookableLocations.filter(
        (location: LocationsDropdownItem) =>
          location.id == (selectedLocationIds[0] as string),
      );
    yield put(setSingleSelectedLocationName(singleLocationDetails?.[0]?.name));
  }
}

function* setBookingSummarySaga(
  action: ReturnType<typeof setSelectedCabinReservationId>,
) {
  const selectedCabinReservationId = action.payload;
  const confirmation: any = yield select(bookingSummarySelector);
  const { bookingReference, cabinReservations } =
    confirmation as BookingConfirmation;

  const bookingSummarySelectedCabin: Nullable<BookingSummary> =
    cabinReservations?.find(
      (cabin) => cabin?.cabinReservationId === selectedCabinReservationId,
    ) as Nullable<BookingSummary>;

  const bookingSummaryOtherCabins: BookingSummary[] = cabinReservations?.filter(
    (cabin) => cabin?.cabinReservationId !== selectedCabinReservationId,
  ) as BookingSummary[];

  if (
    selectedCabinReservationId &&
    bookingSummarySelectedCabin &&
    bookingReference
  ) {
    const selectedCabin = makeCabinProps(bookingSummarySelectedCabin);
    const otherCabins: BookingSummaryCabin[] = bookingSummaryOtherCabins.map(
      (cabin) => makeCabinProps(cabin),
    );

    const booking: any = {
      bookingReference,
      reservationEndDate: bookingSummarySelectedCabin?.reservationEndDate || "",
      reservationStartDate:
        bookingSummarySelectedCabin?.reservationStartDate || "",
      friendlyReservationStartDate: formatDate(
        bookingSummarySelectedCabin?.reservationStartDate || "",
      ),
      friendlyReservationEndDate: formatDate(
        bookingSummarySelectedCabin?.reservationEndDate || "",
      ),
      locationName: bookingSummarySelectedCabin?.locationName || "",
      selectedCabin: selectedCabin as BookingSummaryCabin,
      otherCabins,
    };

    yield put(setBookingSummary(booking));
  }
}

function* getBookingSummaryOnExtraAddRemoveSaga(
  action:
    | ReturnType<typeof addExtraSuccess>
    | ReturnType<typeof addFeaturedExtraSuccess>
    | ReturnType<typeof removeExtraSuccess>
    | ReturnType<typeof addSpecificCabinSuccess>
    | ReturnType<typeof swapSpecificCabinSuccess>
    | ReturnType<typeof removeSpecificCabinSuccess>,
) {
  const { bookingId, confirmedBookingId, confirmedCabinReservationId } =
    yield select(bookingPersistedStateSelector);
  const { confirmation: postConfirmedBooking } = yield select(
    confirmedBookingSummarySelector,
  );
  const { confirmation: preConfirmedBooking } = yield select(
    bookingSummarySelector,
  );
  const activeFilters = yield select(extraFilterSelector);
  const { fhExtrasSteps, fhBookingSteps } = yield select(siteSettingsSelector);
  const currentExtrasStep = getExtrasStepByTag(
    ExtrasStepperTag.extras,
    fhExtrasSteps,
  );

  const currentBookingStep = getBookingStepByTag(
    StepperTag.payment,
    fhBookingSteps,
  );

  const isPostBookingJourney = action.payload.isPostBookingJourney;
  const isBlackwoodForest =
    preConfirmedBooking?.cabinReservations?.[0]?.locationName?.toLocaleLowerCase() ===
      "blackwood forest" ||
    postConfirmedBooking?.cabinReservations?.[0]?.locationName?.toLocaleLowerCase() ===
      "blackwood forest";
  const isBookingPaymentPage = currentBookingStep?.tag === StepperTag.payment;
  const { activeExperiments } = yield select(activeExperimentsStateSelector);
  const experiments =
    !!activeExperiments?.showSpecificCabin && isBlackwoodForest
      ? "FH_RemoveSpecificCabin_FR-587"
      : "";

  if (!!bookingId && !isPostBookingJourney) {
    yield put(getBookingConfirmationAction({ bookingId, experiments }));
    if (isBookingPaymentPage) yield put(getPaymentOptionsAction({ bookingId }));
  }
  if (!!confirmedBookingId && isPostBookingJourney) {
    yield put(
      getConfirmedBookingSummaryAction({
        bookingId: { bookingId: confirmedBookingId, experiments: experiments },
        refreshObtainingQuoteExtras: true,
      }),
    );
    if (confirmedCabinReservationId)
      yield put(
        getConfirmedBookingCabinEssentialsAction({
          reservationId: confirmedCabinReservationId,
          isPostBookingJourney,
        }),
      );
    const { bookingChannelId } = yield select(bookingSearchSelector);
    const isExtrasPage = currentExtrasStep?.tag === ExtrasStepperTag.extras;
    yield put(
      getExtras({
        extrasQuery: {
          cabinReservationId: confirmedCabinReservationId,
          bookingChannelId,
          selectedTagIds: activeFilters,
          sortMethod: "",
        },
        filtered: activeFilters?.length > 0 || false,
        isExtras: isExtrasPage,
      }),
    );
  }
}

function* resetActiveBooking(
  resetActiveBasketPayload?: ResetActiveBasketAndBooking,
) {
  yield put(removeSticky(resetActiveBasketPayload?.isExpOn));
  yield put(resetReservation());
  yield put(resetBooking());
  yield put(resetBookingSummary());
  yield put(resetConfrimedBookingSummary());
  yield put(setBookingStepsToUncompleted());
}

function* getUpcomingBookingsSaga(
  action: ReturnType<typeof getUpcomingBookings>,
) {
  const dynamicGetUpcomingBookings = yield call(
    loadGraphQLQuery,
    "upcomingBookings",
  );
  const upcomingBookingsCall = () =>
    apolloClient.query<
      { upcomingBookings: UpcomingBooking },
      QueryUpcomingBookingsArgs
    >({
      query: dynamicGetUpcomingBookings,
      variables: action.payload,
    });

  try {
    const response = yield call(upcomingBookingsCall);
    const { data, errors } = response;

    if (data?.upcomingBookings) {
      yield put(getUpcomingBookingsSuccess(data.upcomingBookings));
    } else {
      throw Error(
        (errors && errors[0].message) || "Failed to get upcoming bookings",
      );
    }
  } catch (error: any) {
    yield put(getUpcomingBookingsFail(error?.message));
    // appInsights.trackException(error);
  }
}

function* resetActiveBasketAndBookingSaga(
  action: ReturnType<typeof resetActiveBasketAndBooking>,
) {
  yield resetActiveBooking(action.payload);
  if (!action?.payload?.isOnCabinOrLocationsResultsPage)
    yield put(resetBookingSearch());
}

function* getBookingConfirmationSaga(
  action: ReturnType<typeof getBookingConfirmationAction>,
) {
  const dynamicGetBookingSummary = yield call(
    loadCustomGraphQLQuery,
    "bookingConfirmation",
  );
  const { data } = yield select(loginSelector);
  const getSummaryCall = () =>
    apolloClient.query<
      { bookingConfirmation: BookingConfirmationResponseUnion },
      QueryBookingConfirmationArgs
    >({
      query: gql`
        ${dynamicGetBookingSummary}
      `,
      variables: action.payload,
      ...(data?.token
        ? {
            context: {
              headers: {
                authorization: `Bearer ${data.token}`,
              },
            },
          }
        : {}),
      fetchPolicy: "no-cache",
    });

  try {
    const getSummaryResponse = yield call(getSummaryCall);
    const { bookingConfirmation } = getSummaryResponse.data!;
    switch (bookingConfirmation.__typename) {
      case "BookingConfirmationResponse":
        if (bookingConfirmation?.bookingconfirmation) {
          yield put(
            getBookingConfirmationSuccessAction(
              bookingConfirmation.bookingconfirmation,
            ),
          );
        }
        break;
      case "SystemError":
        yield resetActiveBooking();
        throw new Error(bookingConfirmation?.message || "");
      default:
        break;
    }
  } catch (error: any) {
    yield put(
      getBookingConfirmationFailAction(
        error?.message || "Failed to get booking confirmation.",
      ),
    );
    // appInsights.trackException(error);
  }
}

function* getConfirmedBookingSummarySaga(
  action: ReturnType<typeof getConfirmedBookingSummaryAction>,
) {
  const dynamicGetBookingSummary = yield call(
    loadCustomGraphQLQuery,
    "bookingConfirmation",
  );
  const { data } = yield select(loginSelector);
  const token = data?.token;
  if (!!token) {
    const getConfirmedBookingSummaryCall = () =>
      apolloClient.query<
        { bookingConfirmation: BookingConfirmationResponseUnion },
        QueryBookingConfirmationArgs
      >({
        query: gql`
          ${dynamicGetBookingSummary}
        `,
        variables: action.payload.bookingId,
        context: {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
        fetchPolicy: "no-cache",
      });

    try {
      const getConfirmedBookingSummaryResponse = yield call(
        getConfirmedBookingSummaryCall,
      );

      const { bookingConfirmation } = getConfirmedBookingSummaryResponse.data!;

      switch (bookingConfirmation.__typename) {
        case "BookingConfirmationResponse":
          if (bookingConfirmation?.bookingconfirmation) {
            yield put(
              getConfirmedBookingSummarySuccessAction({
                bookingConfirmation: bookingConfirmation?.bookingconfirmation,
                refreshObtainingQuoteExtras:
                  action.payload.refreshObtainingQuoteExtras,
              }),
            );
          }
          break;
        case "SystemError":
          throw new Error(
            bookingConfirmation?.message ||
              "ConfirmedBookingSummary non valid entity error",
          );
        default:
          break;
      }
    } catch (error: any) {
      yield put(
        getConfirmedBookingSummaryFailAction(
          error?.message || "Failed to get ConfirmedBookingSummary.",
        ),
      );
      // appInsights.trackException(error);
    }
  } else {
    const error: any =
      "Unable to get Confirmed Booking Summary due to missing token";
    yield put(getConfirmedBookingSummaryFailAction(error));
    // appInsights.trackException(error);
  }
}

function* getConfirmedBookingInfoSaga(
  action: ReturnType<typeof getConfirmedBookingInfoAction>,
) {
  const dynamicGetConfirmedBookingInfo = yield call(
    loadGraphQLQuery,
    "confirmedBookingInfo",
  );
  const { data } = yield select(loginSelector);
  const token = data?.token;
  if (!!token) {
    const getConfirmedBookingInfoCall = () =>
      apolloClient.query<
        { confirmedBookingInfo: ConfirmedBookingInfoResponseUnion },
        QueryConfirmedBookingInfoArgs
      >({
        query: gql`
          ${dynamicGetConfirmedBookingInfo}
        `,
        variables: action.payload,
        context: {
          headers: {
            authorization: `Bearer ${token}`,
          },
        },
        fetchPolicy: "no-cache",
      });

    try {
      const response = yield call(getConfirmedBookingInfoCall);

      const data = response.data?.confirmedBookingInfo;

      switch (data?.__typename) {
        case "ConfirmedBookingInfoResponse": {
          yield put(getConfirmedBookingInfoSuccessAction(data));
          break;
        }
        case "SystemError": {
          throw Error(data?.message || "");
        }
        default: {
          throw Error("Failed to get confirmed booking info");
        }
      }
    } catch (error: any) {
      yield put(getConfirmedBookingInfoFailAction(error?.message));
      // appInsights.trackException(error);
    }
  } else {
    const error: any =
      "Unable to get Confirmed Booking Info due to missing token.";
    yield put(getConfirmedBookingInfoFailAction(error));
    // appInsights.trackException(error);
  }
}

function* updateLocationOrCabinsFromPromoCodeSaga() {
  const { fhBookingSteps, activeBookingStepIndex } =
    yield select(siteSettingsSelector);
  const activeBookingStep = (fhBookingSteps || [])[activeBookingStepIndex];
  if (activeBookingStep) {
    switch (activeBookingStep.tag) {
      case StepperTag.locations:
        yield bookingSearchUpdatedSaga();
        break;
      case StepperTag.cabins:
        yield put(createCabinSearch());
        break;
    }
  }
}

function* setBookingBedroomSaga(action: ReturnType<typeof setBookingBedroom>) {
  const { cabin, cabinBedroom: bedroom, experiments } = action.payload;
  yield put(setCabinId(cabin.cabinTypeId));
  yield put(setCabinTypeName(cabin.cabinTypeName ?? ""));
  yield put(setPriceCheck(bedroom.priceCheck ?? ""));
  yield put(
    setPrice({
      price: bedroom.postDiscountPrice, // cabins[selectedOption].postDiscountPrice,
      discountPrice: bedroom.postDiscountPrice,
      standardPrice: bedroom.preDiscountCabinPrice,
      tacticalPrice: bedroom.tacticalPrice,
      channelPrice: bedroom.channelPrice,
      cabinSellingPrice: bedroom.cabinSellingPrice,
    }),
  );
  yield put(setPriceExpiry(bedroom.priceExpiry));
  yield put(setNumberOfBedrooms(bedroom.numberOfBedrooms ?? 0));
  yield put(setPetFriendly(bedroom.isPetFriendly ?? false));
  yield put(setIsCabinDda(cabin.isDDA ?? false));
  yield put(initializeAddCabin(experiments));
  yield put(confirmActiveBookingStepAction());
}

function* removeUnconfirmedBookingSaga(
  action: ReturnType<typeof removeUnconfirmedSlice>,
) {
  const dynamicRemoveUnconfirmedBookng = yield call(
    loadGraphQLMutation,
    "removeUnconfirmedBooking",
  );
  const removeUnconfirmedBookingCall = () =>
    apolloClient.mutate<
      { removeUnconfirmedBooking: BookingRemovalResponseUnion },
      MutationRemoveUnconfirmedBookingArgs
    >({
      mutation: gql`
        ${dynamicRemoveUnconfirmedBookng}
      `,
      variables: action.payload,
    });

  const errMessage = "Failed to remove booking";

  try {
    const response = yield call(removeUnconfirmedBookingCall);
    const removeUnconfirmedBooking = response.data?.removeUnconfirmedBooking;
    if (removeUnconfirmedBooking) {
      switch (removeUnconfirmedBooking.__typename) {
        case "BookingRemovalResponse":
          yield put(removeUnconfirmedBookingSuccess(removeUnconfirmedBooking));
          break;
        default:
          throw Error(errMessage);
      }
    } else {
      throw Error(errMessage);
    }
  } catch (error: any) {
    yield put(removeUnconfirmedBookingFailed(error?.message || errMessage));
  }
}

export default function* bookingSagas() {
  yield all([
    takeLatest(
      "bookingSummary/getBookingConfirmationAction",
      getBookingConfirmationSaga,
    ),
    takeLatest(
      "confrimedBookingSummary/getConfirmedBookingSummaryAction",
      getConfirmedBookingSummarySaga,
    ),
    takeLatest(
      "confirmedBookingInfo/getConfirmedBookingInfoAction",
      getConfirmedBookingInfoSaga,
    ),
    takeLatest(
      "bookingSummary/getBookingConfirmationSuccessAction",
      setSelectedCabinReservationIdSaga,
    ),
    takeLatest(
      "confrimedBookingSummary/getConfirmedBookingSummarySuccessAction",
      setConfirmedCabinReservationIdSaga,
    ),
    takeLatest(
      "persistBooking/setConfirmedCabinReservationId",
      setConfirmedBookingSummarySaga,
    ),
    takeLatest("bookingSearch/createLocationSearch", bookingSearchUpdatedSaga),
    takeLatest(
      "locationSearch/setLocationSortMethod",
      bookingSearchUpdatedSaga,
    ),
    takeLatest(
      "bookingSearch/setPromoCode",
      updateLocationOrCabinsFromPromoCodeSaga,
    ),
    takeLatest("bookingSearch/setSelectedLocationIds", setSelectedLocationName),
    takeLatest(
      "bookingSummary/setSelectedCabinReservationId",
      setBookingSummarySaga,
    ),
    takeLatest(
      "extraAdd/addExtraSuccess",
      getBookingSummaryOnExtraAddRemoveSaga,
    ),
    takeLatest(
      "addFeaturedExtra/addFeaturedExtraSuccess",
      getBookingSummaryOnExtraAddRemoveSaga,
    ),
    takeLatest(
      "extraRemove/removeExtraSuccess",
      getBookingSummaryOnExtraAddRemoveSaga,
    ),
    takeLatest(
      "cabinsSpecificAdd/addSpecificCabinSuccess",
      getBookingSummaryOnExtraAddRemoveSaga,
    ),
    takeLatest(
      "cabinsSpecificSwap/swapSpecificCabinSuccess",
      getBookingSummaryOnExtraAddRemoveSaga,
    ),
    takeLatest(
      "cabinsSpecificRemove/removeSpecificCabinSuccess",
      getBookingSummaryOnExtraAddRemoveSaga,
    ),
    takeLatest("bookingUpcoming/getUpcomingBookings", getUpcomingBookingsSaga),
    takeLatest("reservation/setBookingBedroom", setBookingBedroomSaga),
    takeLatest(
      "futureBookings/resetActiveBasketAndBooking",
      resetActiveBasketAndBookingSaga,
    ),
    takeEvery(
      "bookingSummary/removeUnconfirmedBooking",
      removeUnconfirmedBookingSaga,
    ),
  ]);
}
