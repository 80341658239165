import { ExtraOptionStatus } from "./constants/booking";
import {
  Extra,
  ExtraOption,
  ReservationExtra,
  ReservationExtraOption,
} from "@generated/types";
import { SelectedExtraOption } from "./interfaces/extras";
import {
  ActiveBasketExtra,
  ReservationExtra as UIKitReservationExtra,
} from "./interfaces/bookingSummary";
import { ExtraListState } from "./store/slices";

const getAvailableExtra = (
  availableExtras: Extra[],
  extraId: string,
): Extra | undefined =>
  availableExtras.find((extra) => extra.extraId === extraId);

const getAvailableExtraOption = (
  extraOptionId: string,
  extraOptions: ExtraOption[],
): ExtraOption | undefined =>
  (extraOptions || []).find(
    (option) => option?.extraOptionId === extraOptionId,
  );

export const selectedExtraOptions = (
  extras: ReservationExtra[],
): SelectedExtraOption[] => {
  const flatMapOfExtraOptions = extras?.flatMap(
    (extra) => extra?.reservationExtraOptions,
  );

  const confirmedQuantity = (option: ReservationExtraOption): number => {
    return option.extraStatus === ExtraOptionStatus.confirmed
      ? option.quantity || 0
      : 0;
  };

  const obtainingQuoteQuantity = (option: ReservationExtraOption) => {
    return option.extraStatus === ExtraOptionStatus.obtainingQuote
      ? option.quantity || 0
      : 0;
  };

  const totalQuantity = (option: ReservationExtraOption) => {
    return option.quantity || 0;
  };

  const selectedExtraOptions: SelectedExtraOption[] = [];
  flatMapOfExtraOptions.map((option) => {
    //check if already in list
    const index = selectedExtraOptions.findIndex(
      (opt) => opt.extraOptionId === option?.extraOptionId,
    );
    if (index !== -1) {
      // exists, update quantities
      if (!!option) {
        const existingItemInList = selectedExtraOptions[index];

        selectedExtraOptions[index] = {
          extraOptionId: option.extraOptionId,
          confirmedQuantity:
            existingItemInList.confirmedQuantity + confirmedQuantity(option),
          obtainingQuoteQuantity:
            existingItemInList.obtainingQuoteQuantity +
            obtainingQuoteQuantity(option),
          totalQuantity:
            existingItemInList.totalQuantity + totalQuantity(option),
        };
      }
    } else {
      // new, add to list
      if (!!option) {
        selectedExtraOptions.push({
          extraOptionId: option.extraOptionId,
          confirmedQuantity: confirmedQuantity(option),
          obtainingQuoteQuantity: obtainingQuoteQuantity(option),
          totalQuantity: totalQuantity(option),
        });
      }
    }
  });

  return selectedExtraOptions;
};

export const getObtainingQuoteExtras = (
  extras: UIKitReservationExtra[],
): UIKitReservationExtra[] => {
  const extrasWithObtainingQuoteExtraOptions = extras
    .map((extra) => {
      const extraOptionsInObtainingQuote =
        extra.reservationExtraOptions?.filter(
          (opt) => opt?.extraStatus === ExtraOptionStatus.obtainingQuote,
        );
      if ((extraOptionsInObtainingQuote || []).length > 0) {
        const extraWithOnlyObtainingQuoteOptions: UIKitReservationExtra =
          Object.assign({}, extra);
        extraWithOnlyObtainingQuoteOptions.reservationExtraOptions =
          extraOptionsInObtainingQuote;

        return extraWithOnlyObtainingQuoteOptions;
      }
    })
    .filter((extra) => !!extra) as UIKitReservationExtra[];

  return extrasWithObtainingQuoteExtraOptions;
};

export enum ExtraOptionPrice {
  totalPrice = "TOTALPRICE",
  sellingPrice = "SELLINGPRICE",
}

export type ExtraPromoDataProps = {
  code: string;
  websiteHeadline: string;
  value: number;
};

export const getExtraPromoForObtainingQuoteExtrasPostBooking = (
  extras: UIKitReservationExtra[],
): ExtraPromoDataProps => {
  const extraOptionFlatMap = getObtainingQuoteExtras(extras)?.flatMap(
    (extra) => extra?.reservationExtraOptions || [],
  );
  const extraOptionWithPromo = extraOptionFlatMap.find(
    (option) => !!option?.extraPromotionCode,
  );
  if (!!extraOptionWithPromo) {
    const { extraPromotionCode, extraPromotionWebsiteHeadline } =
      extraOptionWithPromo;
    const obtQuoteTotalPrice = getPriceOfObtainingQuoteExtras(
      (extras || []) as UIKitReservationExtra[],
      ExtraOptionPrice.totalPrice,
    );
    const obtQuoteSellingPrice = getPriceOfObtainingQuoteExtras(
      (extras || []) as UIKitReservationExtra[],
      ExtraOptionPrice.sellingPrice,
    );
    const extraPromoAmount =
      obtQuoteTotalPrice > obtQuoteSellingPrice
        ? obtQuoteTotalPrice - obtQuoteSellingPrice
        : 0;
    return {
      code: extraPromotionCode,
      websiteHeadline: extraPromotionWebsiteHeadline,
      value: extraPromoAmount,
    };
  }
  return { code: "", websiteHeadline: "", value: 0 };
};

export const getPriceOfObtainingQuoteExtras = (
  extras: UIKitReservationExtra[],
  price: ExtraOptionPrice,
) => {
  const extraOptionFlatMap = getObtainingQuoteExtras(extras)?.flatMap(
    (extra) => extra?.reservationExtraOptions || [],
  );
  const prices = extraOptionFlatMap?.map((option) =>
    price === ExtraOptionPrice.sellingPrice
      ? formatCurrency(option?.sellingPrice)
      : formatCurrency(option?.totalPrice) || 0,
  );
  const totalValue = prices.reduce((a, b) => a + b, 0);
  return totalValue;
};

export const formatCurrency = (num?: number): number => {
  if (!num) {
    return 0;
  } else {
    return +(Math.round(num * 100) / 100).toFixed(2);
  }
};

export const getBasketExtrasWithPriceCheck = (
  availableExtras: Extra[],
  basketExtras: ReservationExtra[],
): ReservationExtra[] => {
  return basketExtras.map((extra) => {
    const availableExtra = getAvailableExtra(availableExtras, extra.extraId);

    return {
      ...extra,
      reservationExtraOptions: extra.reservationExtraOptions?.map((option) => {
        const availableOption = getAvailableExtraOption(
          option?.extraOptionId,
          availableExtra?.extraOptions as ExtraOption[],
        );

        return (
          option && {
            ...option,
            priceCheck: availableOption?.priceCheck || "",
            priceExpiry: availableOption?.priceExpiry,
          }
        );
      }),
    };
  });
};

export const calculateTotalExtrasPrice = (extras: ReservationExtra[]) =>
  extras
    .flatMap((extra) => extra.reservationExtraOptions || [])
    .map((option) => option?.totalPrice)
    .reduce((a, b) => a + b, 0);

export const makeBasketData = (extrasState: ExtraListState) => ({
  ...(extrasState as ExtraListState),
  extras:
    (extrasState?.data?.basketExtras || []).map(
      (extra) =>
        ({
          ...extra,
          totalPrice: extra?.reservationExtraOptions?.reduce(
            (prev, curr) => prev + (curr?.totalPrice || 0),
            0,
          ),
        }) as ActiveBasketExtra,
    ) || [],
});
